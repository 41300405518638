import { createSlice } from "@reduxjs/toolkit";
import { SERVICE_URL } from "config.js";
import axios from "axios";

const initialState = {
  events: [],
  selectedEvent: {},
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload;
    },
    setSelectedEvent(state, action) {
      state.selectedEvent = action.payload;
    },
  },
});
const { setEvents, eventsType } = calendarSlice.actions;
export const { setSelectedEvent } = calendarSlice.actions;

export const getEvents = (startStr, endStr, teamcode) => async (dispatch) => {
  let response;
  if (teamcode.length === 0) {
    response = await axios.get(
      `${SERVICE_URL}calendar?start=${startStr}&end=${endStr}`,
      { headers: { Authorization: localStorage.getItem("persist-id") } }
    );
  } else {
    response = await axios.post(
      `${SERVICE_URL}calendar/team`,
      { start: startStr, end: endStr, teamcode: teamcode },
      { headers: { Authorization: localStorage.getItem("persist-id") } }
    );
  }
  const holydays = await axios.get(
    `${SERVICE_URL}holydays?start=${startStr}&end=${endStr}`,
    { headers: { Authorization: localStorage.getItem("persist-id") } }
  );

  dispatch(setEvents([...holydays.data.data, ...response.data.data]));
};

export const createEvent =
  (taskcode, startStr, endStr, startDate, endDate, teamcode) =>
  async (dispatch) => {
    let response = [];
    let holydays = [];

    const responseteams = await axios
      .put(
        `${SERVICE_URL}task/teams`,
        { taskcode: taskcode, teamcode: teamcode },
        { headers: { Authorization: localStorage.getItem("persist-id") } }
      )
      .then(
        await axios
          .put(
            `${SERVICE_URL}calendar`,
            {
              taskcode: taskcode,
              date: startStr,
              start: startStr,
              end: endStr,
              startDate: startDate,
              endDate: endDate,
              teamcode: teamcode,
            },
            { headers: { Authorization: localStorage.getItem("persist-id") } }
          )
          .then((responses) => (response = responses))
          .catch((error) => {
            console.log(error);
          })
      )
      .catch((error) => {
        console.log(error);
      });

    await axios
      .get(`${SERVICE_URL}holydays?start=${startStr}&end=${endStr}`, {
        headers: { Authorization: localStorage.getItem("persist-id") },
      }).then(responses => holydays = responses)
      .catch((error) => {
        console.log(error);
      });

    dispatch(setEvents([...holydays.data.data, ...response.data.data]));
  };

export const updateEvent = (event, teamcode) => async (dispatch) => {
  const response = await axios
    .post(
      `${SERVICE_URL}calendar`,
      {
        taskcode: event.taskcode,
        consecutive: event.consecutive,
        start: event.start,
        end: event.end,
        startDate: event.startDate,
        endDate: event.endDate,
        teamcode: teamcode,
      },
      { headers: { Authorization: localStorage.getItem("persist-id") } }
    )
    .catch((error) => {
      console.log(error);
    });

  const holydays = await axios.get(
    `${SERVICE_URL}holydays?start=${event.start}&end=${event.end}`,
    { headers: { Authorization: localStorage.getItem("persist-id") } }
  );

  dispatch(setEvents([...holydays?.data?.data, ...response.data.data]));
};
export const deleteEvent =
  (taskcode, consecutive, startStr, endStr, teamcode) => async (dispatch) => {
    const responses = await axios.delete(
      `${SERVICE_URL}calendar?taskcode=${taskcode}&consecutive=${consecutive}&start=${startStr}&end=${endStr}`,
      { headers: { Authorization: localStorage.getItem("persist-id") } }
    );

    const response = await axios.post(
      `${SERVICE_URL}calendar/team`,
      { start: startStr, end: endStr, teamcode: teamcode },
      { headers: { Authorization: localStorage.getItem("persist-id") } }
    );

    const holydays = await axios.get(
      `${SERVICE_URL}holydays?start=${startStr}&end=${endStr}`,
      { headers: { Authorization: localStorage.getItem("persist-id") } }
    );

    dispatch(setEvents([...holydays?.data?.data, ...response.data.data]));
  };

const calendarReducer = calendarSlice.reducer;

export default calendarReducer;
