import { DEFAULT_PATHS } from "config.js";
import { lazy } from "react";

const Profile = lazy(() => import("views/master/profile/profile"));
const Calendar = lazy(() => import("views/apps/calendar/Calendar"));
const CalendarTeam = lazy(() => import("views/apps/calendarTeam/Calendar"));
const ODT = lazy(() => import("views/management/workorder/Odt"));
const Report = lazy(() => import("views/management/report/report"));
const Task = lazy(() => import("views/management/task/Task"));
const Vacation = lazy(() => import("views/management/vacation/Vacation"));
const PrintWorkOrder = lazy(() => import("prints/workorder/PrintWorkOrder"));
const PrintIssue = lazy(() => import("prints/issue/PrintIssue"));
const PrintR081 = lazy(() => import("prints/issue/PrintR081"));
const IssueOrder = lazy(() => import("views/management/issueorder/Issue")); 
const City = lazy(() => import("views/master/city/city"));
const Position = lazy(() => import("views/master/position/position")); // Ruta maestro "Cargo"
const Category = lazy(() => import("views/master/category/category"));
const Service = lazy(() => import("views/master/service/service"));
const Workhour = lazy(() => import("views/master/workhour/workhour"));
const Files = lazy(() => import("views/master/files/File"));
const Team = lazy(() => import("views/master/team/Team"));
const Parameter = lazy(() => import("views/master/parameter/Parameter"));
const Issue = lazy(() => import("views/master/issue/Issue"));
const Costumer = lazy(() => import("views/master/costumer/costumer"));
const User = lazy(() => import("views/master/user/user"));
const Horizontal = lazy(() => import("views/Horizontal"));
const CalendarIssue = lazy(()=>import("views/apps/calendarIssue/Calendar"));

// inventarios
const ProductCategory = lazy(() => import("views/storage/productCategory/productCategory"));
const Inventary = lazy(() => import("views/storage/inventary/inventary"));
const Products = lazy(() => import("views/storage/products/products"));
const Dispatches = lazy(() => import("views/storage/dispatches/Dispatches"));
const ProductKits = lazy(() => import("views/storage/productskits/productskits"));

const Suppliers = lazy(() => import("views/storage/suppliers/Suppliers"));
const Endowment= lazy(() => import("views/master/endowment/endowment"));
const EndowmentTeam = lazy(() => import("views/management/endowment/Endowment"));

function Transformar() {
  if (localStorage.getItem("persist-path") == undefined) {
    r = {
      mainMenuItems: [
        {
          path: DEFAULT_PATHS.APP,
          exact: true,
          redirect: true,
          to: `${appRoot}/home`,
          protected: true,
        },
        {
          path: `home`,
          component: Horizontal,
          label: "menu.horizontal",
          icon: "home",
          protected: true,
        },
      ],
      sidebarItems: [],
    };
  } else {
    var r = JSON.parse(localStorage.getItem("persist-path"));

    if (
      r.mainMenuItems.find((item) => item.path === "/calendar") !== undefined
    ) {
      if (
        r.mainMenuItems
          .find((item) => item.path === "/calendar")
          ?.subs?.find((item) => item.path === "/servicesCalendar") !==
        undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/calendar")
          .subs.find((item) => item.path === "/servicesCalendar").component =
          Calendar;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/calendar")
          ?.subs?.find((item) => item.path === "/servicesTeam") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/calendar")
          .subs.find((item) => item.path === "/servicesTeam").component =
          CalendarTeam;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/calendar")
          ?.subs?.find((item) => item.path === "/servicesIssue") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/calendar")
          .subs.find((item) => item.path === "/servicesIssue").component =
          CalendarIssue;
      }
    }

    if (r.mainMenuItems.find((item) => item.path === "/storage")){

      if (
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          ?.subs?.find((item) => item.path === "/productCategory")) {
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          .subs.find((item) => item.path === "/productCategory").component = ProductCategory;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          ?.subs?.find((item) => item.path === "/productsKits")) {
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          .subs.find((item) => item.path === "/productsKits").component = ProductKits;
      }


      if (
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          ?.subs?.find((item) => item.path === "/inventary")) {
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          .subs.find((item) => item.path === "/inventary").component = Inventary;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          ?.subs?.find((item) => item.path === "/products")) {
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          .subs.find((item) => item.path === "/products").component = Products;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          ?.subs?.find((item) => item.path === "/dispatches")) {
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          .subs.find((item) => item.path === "/dispatches").component = Dispatches;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          ?.subs?.find((item) => item.path === "/suppliers")) {
        r.mainMenuItems
          .find((item) => item.path === "/storage")
          .subs.find((item) => item.path === "/suppliers").component = Suppliers;
      }
    }

    if (
      r.mainMenuItems.find((item) => item.path === "/masters") !== undefined
    ) {
      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/profile") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/profile").component = Profile;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/service") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/service").component = Service;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/workHour") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/workHour").component = Workhour;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/files") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/files").component = Files;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/serviceCategory") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/serviceCategory").component =
          Category;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/city") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/city").component = City;
      }

      if ( r.mainMenuItems.find( item => item.path === '/masters')?.subs?.find( item => item.path === '/position') !== undefined ) {
        r.mainMenuItems.find( item => item.path === '/masters').subs.find( item => item.path === '/position').component = Position;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/customer") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/customer").component = Costumer;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/team") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/team").component = Team;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/parameter") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/parameter").component =
          Parameter;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/issue") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/issue").component =
          Issue;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          ?.subs?.find((item) => item.path === "/user") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/masters")
          .subs.find((item) => item.path === "/user").component = User;
      
        }

        if (
          r.mainMenuItems
            .find((item) => item.path === "/masters")
            ?.subs?.find((item) => item.path === "/endowment") !== undefined
        ) {
          r.mainMenuItems
            .find((item) => item.path === "/masters")
            .subs.find((item) => item.path === "/endowment").component = Endowment;
        }

    }
    if (
      r.mainMenuItems.find((item) => item.path === "/management") !== undefined
    ) {
      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/workorder") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/workorder").component = ODT;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/report") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/report").component = Report;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/endowment") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/endowment").component = EndowmentTeam;
      }


      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/workorder") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/printworkorder").component =
          PrintWorkOrder;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/printIssue") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/printIssue").component =
          PrintIssue;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/printR081") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/printR081").component =
          PrintR081;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/issueorder") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/issueorder").component =
          IssueOrder;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/workorder") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/task").component = Task;
      }

      if (
        r.mainMenuItems
          .find((item) => item.path === "/management")
          ?.subs?.find((item) => item.path === "/vacation") !== undefined
      ) {
        r.mainMenuItems
          .find((item) => item.path === "/management")
          .subs.find((item) => item.path === "/vacation").component = Vacation;
      }
    }

    if (r.mainMenuItems.find((item) => item.path === "/home") !== undefined) {
      r.mainMenuItems.find((item) => item.path === "/home").component =
        Horizontal;
    }

    localStorage.setItem("persist-path", JSON.stringify(r));
  }
  return r;
}

const appRoot = DEFAULT_PATHS.APP.endsWith("/")
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const routesAndMenuItems = Transformar();

export default routesAndMenuItems;

export { routesAndMenuItems, Transformar };
