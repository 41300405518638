const enMessages = {

  'input.salary':'El campo Salario sólo permite  letras y numeros.',
  'input.costcenter':'El campo Centro de Costo sólo permite letras y numeros.',
  'input.askfor':'El campo preguntar por, sólo permite letras y espacios.',
  'input.address':'El campo dirección permite letras, numeros y  (._#)',
  'input.other_salary' : 'Los campos de otros salarios, solo permite numeros.',
  'input.description' : 'El campo descripción, sólo permite letras, numeros y (=/.:).',
  'input.startdate' : 'El campo fecha inicial sólo permite numeros y (/).',
  'input.startime' : 'El campo hora inicial sólo permite numeros y (:) .',
  'input.telephonenumber' : 'El campo teléfono, solo permite numeros.',
  'menu.dashboards': 'Tablero principal',
  'menu.default': 'es|Default',
  'menu.visual': 'es|Visual',
  'menu.analytic': 'es|Analytic',
  'menu.apps': 'es|Apps',
  'menu.calendar': 'es|Calendar',
  'menu.chat': 'es|Chat',
  'menu.contacts': 'es|Contacts',
  'menu.mailbox': 'es|Mailbox',
  'menu.tasks': 'es|Tasks',
  'menu.pages': 'es|Pages',
  'menu.authentication': 'es|Authentication',
  'menu.login': 'es|Login',
  'menu.emailpre': 'Email de Pre-Aprobación', 
  'menu.register': 'es|Register',
  'menu.forgot-password': 'es|Forgot Password',
  'menu.reset-password': 'es|Reset Password',
  'menu.blog': 'es|Blog',
  'menu.home': 'es|Home',
  'menu.grid': 'es|Grid',
  'menu.list': 'es|List',
  'menu.detail': 'es|Detail',
  'menu.miscellaneous': 'es|Miscellaneous',
  'menu.faq': 'es|Faq',
  'menu.knowledge-base': 'es|Knowledge Base',
  'menu.error': 'es|Error',
  'menu.coming-soon': 'es|Coming Soon',
  'menu.pricing': 'es|Pricing',
  'menu.search': 'Buscar',
  'menu.transferEndownment': 'Transferencia Almacen',
  'menu.mailing': 'es|Mailing',
  'menu.empty': 'es|Empty',
  'menu.portfolio': 'es|Portfolio',
  'menu.profile': 'Perfil',
  'menu.standard': 'es|Standard',
  'menu.settings': 'es|Settings',
  'menu.blocks': 'es|Blocks',
  'menu.images': 'es|Images',
  'menu.tabular-data': 'es|Tabular Data',
  'menu.thumbnails': 'es|Thumbnails',
  'menu.cta': 'es|Cta',
  'menu.gallery': 'es|Gallery',
  'menu.stats': 'es|Stats',
  'menu.steps': 'es|Steps',
  'menu.details': 'es|Details',
  'menu.copy' : 'Copiar Link de la Imagen',
  'menu.interface': 'es|Interface',
  'menu.components': 'es|Components',
  'menu.accordion': 'es|Accordion',
  'menu.alerts': 'es|Alerts',
  'menu.badge': 'es|Badge',
  'menu.breadcrumb': 'es|Breadcrumb',
  'menu.buttons': 'es|Buttons',
  'menu.button-group': 'es|Button Group',
  'menu.card': 'es|Card',
  'menu.close-button': 'es|Close Button',
  'menu.close': 'Cerrar',
  'menu.collapse': 'es|Collapse',
  'menu.dropdowns': 'es|Dropdowns',
  'menu.list-group': 'es|List Group',
  'menu.modal': 'es|Modal',
  'menu.navs': 'es|Navs',
  'menu.offcanvas': 'es|Offcanvas',
  'menu.pagination': 'es|Pagination',
  'menu.popovers': 'es|Popovers',
  'menu.progress': 'es|Progress',
  'menu.spinners': 'es|Spinners',
  'menu.toasts': 'es|Toasts',
  'menu.tooltips': 'es|Tooltips',
  'menu.forms': 'es|Forms',
  'menu.layouts': 'es|Layouts',
  'menu.validation': 'es|Validation',
  'menu.wizard': 'es|Wizard',
  'menu.input-group': 'es|Input Group',
  'menu.input-mask': 'es|Input Mask',
  'menu.generic-forms': 'es|Generic Forms',
  'menu.controls': 'es|Controls',
  'menu.autocomplete': 'es|Autocomplete',
  'menu.checkbox-radio': 'es|Checkbox-Radio',
  'menu.date-picker': 'es|Date Picker',
  'menu.dropzone': 'es|Dropzone',
  'menu.editor': 'es|Editor',
  'menu.input-spinner': 'es|Input Spinner',
  'menu.rating': 'es|Rating',
  'menu.select': 'es|Select',
  'menu.slider': 'es|Slider',
  'menu.tags': 'es|Tags',
  'menu.time-picker': 'es|Time Picker',
  'menu.plugins': 'es|Plugins',
  'menu.carousel': 'es|Carousel',
  'menu.charts': 'es|Charts',
  'menu.clamp': 'es|Clamp',
  'menu.context-menu': 'es|Context Menu',
  'menu.datatables': 'es|Datatables',
  'menu.editable-rows': 'es|Editable Rows',
  'menu.editable-boxed': 'es|Editable Boxed',
  'menu.server-side': 'es|Server Side',
  'menu.boxed-variations': 'es|Boxed Variations',
  'menu.lightbox': 'es|Lightbox',
  'menu.maps': 'es|Maps',
  'menu.players': 'es|Players',
  'menu.scrollbar': 'es|Scrollbar',
  'menu.shortcuts': 'es|Shortcuts',
  'menu.sortable': 'es|Sortable',
  'menu.content': 'es|Content',
  'menu.icons': 'es|Icons',
  'menu.cs-line-icons': 'es|CS Line Icons',
  'menu.cs-interface-icons': 'es|CS Interface Icons',
  'menu.bootstrap-icons': 'es|Bootstrap Icons',
  'menu.tables': 'es|Tables',
  'menu.typography': 'es|Typography',
  'menu.menu': 'es|Menu',
  'menu.horizontal': 'Inicio',
  'menu.programacion':'Programación',
  'menu.maestros':'Maestros',
  'menu.perfiles':'Perfiles',
  'menu.permisos':'Permisos',
  'menu.servicios':'Servicios',
  'menu.incapacidades':'Incapcidades',
  'menu.vacaciones':'Vacaciones',
  'menu.agregar' : 'Nuevo',
  'menu.edit' : 'Editar',
  'menu.delete' : 'Eliminar',
  'menu.vertical': 'es|Vertical',
  'menu.vertical-hidden': 'es|Vertical Hidden',
  'menu.vertical-no-hidden': 'es|Vertical No Hidden',
  'menu.mobile-only': 'es|Mobile Only',
  'menu.sidebar': 'es|Sidebar',
  'menu.Docs': 'es|Docs',
  'menu.connections': 'es|Connections',
  'menu.bookmarks': 'es|Bookmarks',
  'menu.requests': 'es|Requests',
  'menu.account': 'es|Account',
  'menu.password': 'es|Password',
  'menu.devices': 'es|Devices',
  'menu.notifications': 'es|Notifications',
  'menu.email': 'es|Email',
  'menu.sms': 'es|SMS',
  'menu.downloads': 'es|Downloads',
  'menu.documents': 'es|Documents',
  'menu.videos': 'es|Videos',
  'menu.dashboard': 'es|Dashboard',
  'menu.products': 'es|Products',
  'menu.orders': 'es|Orders',
  'menu.customers': 'es|Customers',
  'menu.storefront': 'es|Storefront',
  'menu.filters': 'es|Filters',
  'menu.categories': 'es|Categories',
  'menu.cart': 'es|Cart',
  'menu.checkout': 'es|Checkout',
  'menu.invoice': 'es|Invoice',
  'menu.shipping': 'es|Shipping',
  'menu.discount': 'es|Discount',
  'menu.general': 'es|General',
  'menu.taskschedual' : 'Programar Tareas',
  'menu.assigntasks' :'Asignar Tareas',
  'menu.upgrade': 'es|Upgrade',
  'menu.community': 'es|Community',
  'menu.community-list': 'es|Community List',
  'menu.getting-started': 'es|Getting Started',
  'menu.analysis': 'es|Analysis',
  'menu.services': 'es|Services',
  'menu.database': 'es|Database',
  'menu.database-add': 'es|New Database',
  'menu.database-detail': 'es|Database Detail',
  'menu.storage': 'es|Storage',
  'menu.hosting': 'es|Hosting',
  'menu.billing': 'es|Billing',
  'menu.security': 'es|Security',
  'menu.support': 'es|Support',
  'menu.docs': 'es|Docs',
  'menu.docsdetail': 'es|Docs Detail',
  'menu.tickets': 'es|Tickets',
  'menu.tickets-detail': 'es|Teicket Detail',
  'menu.items' : 'Elementos',
  'menu.invoicecode' : 'Factura',
  'menu.elearning': 'es|Elearning',
  'menu.school': 'es|School',
  'menu.courses': 'es|Courses',
  'menu.explore': 'es|Explore',
  'menu.quiz': 'es|Quiz',
  'menu.result': 'es|Result',
  'menu.paths': 'es|Paths',
  'menu.alertnumbers': 'Cantidad de alertas anuales',
  'menu.instructor': 'es|Instructor',
  'menu.misc': 'es|Miscellaneous',
  'menu.player': 'es|Player',
  'menu.material': 'es|Material',
  'menu.syllabus': 'es|Syllabus',
  'menu.ciudad' : 'Ciudad',
  'menu.cargos' : 'Cargos',
  'menu.alertdays' : 'Días previos al vencimiento',
  'menu.total' : 'Total',
  'menu.entregas' : 'Entregas de dotación',
  'menu.dotaciones' : 'Configuración de alertas',
  'menu.categoria' : 'Categorías',
  'menu.servicioscalendario' : 'Calendario de ODT',
  'menu.reference' :'Referencia/Factura',
  'menu.titulodespachos': 'Despachos/Entradas',
  'menu.typetransaction' :'Tipo de transacción',
  'menu.grouptransaction': 'Grupo de Transacción',
  'menu.storage' :'Bodega',
  'menu.storagein' : 'Bodega de Entrada',
  'menu.count': 'Cantidad',
  'menu.storageout' : 'Bodega de Salida',
  'table.transactionstate' : 'Estado de transacción',
  'menu.team': 'Cuadrillas',
  'menu.gestion': 'Gestión',
  'menu.ordentrabajo' : 'Ordenes de Trabajo',
  'menu.parametro' :'Parámetros',
  'menu.clientes' : 'Clientes',
  'menu.novedad': 'Novedades',
  'menu.user' : 'Usuarios',
  'menu.users' : 'Usuario',
  'menu.role' : 'Rol',
  'menu.typeissue': 'Tipo de Novedad',
  'menu.changepassword' : 'Cambiar Contraseña',
  'menu.confirmpassword' : 'Confirmar Contraseña',
  'menu.updatepassword' : 'Editar Contraseña',
  'menu.titleworkorder' : 'Gestión de Ordenes de Trabajo',
  'menu.titleissue' : 'Gestión de Novedades',
  'menu.observation' : 'Observación',
  'menu.proveedores' : 'Proveedores',
  'menu.employee': 'Empleado',
  'menu.almacen': 'Almacen',
  'menu.inventario': 'Inventario',
  'menu.existencias': 'Existencias',
  'menu.producto': 'Productos',
  'menu.productokits': 'Paquetes de dotación',
  'menu.categoriap': 'Categoria de productos',
  'menu.despachos': 'Despachos/Entradas',
  'table.code' :'Código',
  'table.order' :'Orden',
  'table.name' :'Nombre',
  'table.active' :'Activo',
  'table.cancel' :'Cancelar',
  'table.reference' :'Referencia',
  'menu.unit' :'Unidad',
  'table.taxes' :'Impuestos',
  'table.done' : 'Aceptar',
  'table.final' : 'Hora final',
  'table.quote' : 'Cotización',
  'table.init' : 'Hora inicial',
  'table.initdate' : 'Fecha - Hora inicial',
  'table.finaldate' : 'Fecha - Hora final',
  'table.extension' : 'Duración (H)',
  'table.value': 'Valor',
  'table.dafaultvalue' : 'Valor Defecto',
  'table.unit' : 'Unidad',
  'table.description' : 'Descripción',
  'table.document' : 'Documento',
  'menu.issue': 'Novedades',
  'menu.r081': 'Gestión de R081',
  'menu.courses': 'Cursos',
  'menu.typeissue': 'Tipos de Novedades',
  'menu.typereason': 'Motivos',
  'table.modify':"Eliminable",
  'table.state' : 'Estado',
  'table.email' : 'Email',
  'table.lastModified' : 'Fecha modificación',
  'table.size' : 'Tamaño del archivo',
  'table.arl': 'Arl',
  'table.eps': 'EPS',
  'table.ccf': 'Caja de compensación',
  'table.afp': 'Fondo de Pensiones',
  'menu.reportes'  : 'Reportes',
  'table.address' : 'Dirección',
  'table.mobilenumber' : 'Número Celular',
  'table.password' : 'Contraseña',
  'table.image' : 'Link imagen',
  'table.long' : 'Descripción Larga',
  'table.short' : 'Descripcion Corta',
  'menu.creationdate' : 'Fecha de Creación',
  'menu.initdate' : 'Fecha Inicial',
  'menu.finaldate' : 'Fecha Date',
  'menu.generate' : 'Generar',
  'menu.notification'  : 'Notificación',
  'menu.title'  : 'Titulo',
  'menu.files'  : 'Archivos',
  'menu.preview': 'Previsualizar',
  'menu.serviciosnovedad' : 'Calendario de Novedades',
  'menu.download' : 'Descargar',
  'menu.position': 'Cargo',
  'menu.cargos': 'Cargos',
  'menu.startdate': 'Fecha Inicio',
  'menu.starttime': 'Hora Inicio',
  'menu.workhour' : 'Horarios de trabajo',
  'menu.load' : 'Cargar',
  'menu.salary': 'Salario',
  'menu.othersalary': 'Otros Salarios',
  'menu.hiring': 'Contratación',
  'menu.arl': 'Arl',
  'menu.id': 'Cédula',
  'menu.vacaciones' : 'Vacaciones',
  'menu.telephonenumber' : 'Teléfono',
  'menu.askfor': 'Preguntar por',
  'menu.costcenter': 'Centro de Costo',
  'menu.tareas'  : 'Tareas',
  'table.costumer' : 'Cliente',
  'menu.servicioscuadrilla' : 'Calendario de Cuadrillas', 
  'message.selector' : 'Seleccione',
  'menu.impresionodt' : 'Imprimir ODT',
  'menu.impresionnovedad' : 'Imprimir Novedad',
  'menu.impresionR082' : 'Imprimir R082',
  'menu.agregartarea' :'Agregar tarea',
  'message.datetimeinit' : 'La Fecha inicial no es valida, ingrese otro e intente nuevamente.',
  'message.datetimefinal' : 'La Fecha final no es valida, ingrese otro e intente nuevamente.',
  'message.dates' : 'La Fecha inicial no puede mayor a la fecha final, seleccione otras e intente nuevamente.',
  'message.worktime' : 'El Horario de trabajo no es valido, seleccione otro e intente nuevamente.',
  'message.tareasadjuntas' : 'Se debe asociar al menos una tarea, asocie una e intente nuevamente.',
  'message.employee': 'El empleado seleccionado no es valido (se debe seleccionar un empleado), seleccionelo e intente nuevamente.',
  'message.title' : 'El titulo no es valido, ingrese otro e intente nuevamente.',
  'message.delete' : '¿Esta seguro que desea borrar el registro?',
  'message.issue' :'El motivo seleccionado no es valido (El motivo es obligatoiro), seleccione uno intente nuevamente.',
  'message.name' : 'El nombre no es valido (el nombre debe ser de mínimo 4 caractreres), ingrese otro e intente nuevamente.',
  'message.extension' : 'La duración no es valida (la duración debe ser de al menos 1 hora), ingrese otro e intente nuevamente.',
  'message.hours' :'Las horas seleccionadas no son validas (La hora final debe ser mayor que la inicial), ingrese otras e intente nuevamente.',
  'message.city': 'La ciudad seleccionada no es valida (se debe seleccionar una ciudad), seleccione otro e intente nuevamente.',
  'message.positionname': 'El nombre no es valido (el nombre debe ser de mínimo 3 caractreres), ingrese otro e intente nuevamente.',
  'message.wotype' : 'El tipo de novedad seleccionada no es valida (se debe seleccionar al menos una), seleccione e intente nuevamente',
  'message.category': 'La categoría seleccionada no es valida (se debe seleccionar una cateogría), seleccionelo e intente nuevamente.',
  'message.costumer': 'El cliente seleccionado no es valido (se debe seleccionar un cliente), seleccionelo e intente nuevamente.',
  'message.selected' : 'Seleccione una ciudad para que cargar los servicios.',
  'message.unit' : 'La unidad no es valida, ingrese otra e intente nuevamente.',
  'message.description' : 'La descripción no es valida, ( debe contener minimo 10 caracteres), ingrese otro e intente nuevamente.',
  'message.defaultvalue' : 'El valor por defecto no es valido, ingrese otro e intente nuevamente.',
  'message.parametervalue' : 'El valor del parámetro no es valido, ingrese otro e intente nuevamente.',
  'message.email' : 'El email no es valido ( el email debe ser de mínimo 5 caracteres y tener formato xxx@zzz.yyy), ingrese otro e intente nuevamente. ',
  'message.document' : 'El documento/nit no es valido, ingrese otro e intente nuevamente.',
  'message.mobilenumber' : 'El número de télefono no es valido, ingrese otro e intente nuevamente.',
  'message.address' : 'La dirección no es valida, ingrese otra e intente nuevamente.',
  'message.emptyform' : 'El formulario esta vacio, ingrese los datos e intente nuevamente',
  'message.profile': 'El perfil seleccionado no es valido (se debe seleccionar uno), seleccione otro e intente nuevamente.',
  'message.position': 'El cargo seleccionado no es valido (se debe seleccionar uno), seleccione otro e intente nuevamente.',
  'message.role': 'El rol seleccionado no es valido (se debe seleccionar uno), seleccione otro e intente nuevamente',
  'message.password' : 'La contraseña debe tener al menos una letra en mayuscula, una en minuscula, un numero y un caracter especial (+/*$@!%?&) y tener mínimo 4 caracteres, ingrese otro e intente nuevamente.',
  'message.user' : 'El usuario no es valído, ingrese otro e intente nuevamente.',
  'message.username' : 'El usuario no es valido( el usuario debe tener al menos 4 caracteres, incluir una letra y un numero), ingrese otro e intente nuevamente.',
  'message.samepassword': 'Las contraseñas no coinciden, ingrese otra e intente nuevamente.',
  'message.team': 'El usuario seleccionado no es valido (se debe seleccionar uno), intente nuevamente.',
  'message.services': 'Se debe seleccionar al menos un servicio, intente nuevamente.',
  'message.teams' : 'Se debe ingresar un número valido de cuadrillas, intente nuevamente.',
  'message.image' : 'Se debe ingresar un link, intente nuevamente.',
  'message.short' : 'Se debe ingresar una descripción, intente nuevamente.',
  'message.long' : 'Se debe ingresar una descripción larga, intente nuevamente.',
  'message.value' : 'El valor ingesado no es valido, intente nuevamente.',
  'message.costumertype' : 'El tipo de documento de cliente no es valido, seleccione uno e intente nuevamente.',
};
export default enMessages;
